import React from 'react';

// Function to get the Oikotie ad as a fragment
const getOikotieFragment = (iframeRef: React.RefObject<HTMLIFrameElement>) => (
    <React.Fragment key={`ad-item-5`}>
        <iframe
            ref={iframeRef}
            src={`https://recommendations.asunnot.oikotie.fi/tori-aurora-front-page.html`}
            className={`relative isolate recommendation-ad card card--cardShadow s-bg`}
            width="100%"
            height="100%"
        />
    </React.Fragment>
);

// Function to insert the Oikotie ad after the first 4 ads
export const insertOikotieAd = (
    elements: JSX.Element[],
    iframeRef: React.RefObject<HTMLIFrameElement>,
): JSX.Element[] => {
    const elementsPart1 = elements.slice(0, 4);
    const elementsPart2 = elements.slice(4);
    return [...elementsPart1, getOikotieFragment(iframeRef), ...elementsPart2];
};
