import React, { useMemo, useRef, useState } from 'react';
import { Item, RecommendationData } from '../data/recommendationDataTypes';
import { useInfiniteFetchMoreData } from '../data/recommendationDataHooks';
import PulseTracking from '../tracking/pulseTracking';
import { usePrefetchRecommendationDataQuery } from '../data/recommendationData';
import { useBottomReached } from '../tracking/bottomReachedTracking';
import { Button } from '@warp-ds/react';
import { getTitle, showHistoryLink, showLoadMoreButton } from './adHelpers';
import { insertOikotieAd } from './OikotieAd';
import AdvertisingAd, { itemIsAdvertising } from './AdvertisingAd';
import { useAppConfig } from '../utils/AppConfig';
import Ad from './Ad';

type AdsProps = {
    position: string;
    recommender: string;
    adId: number;
    numColumns: number;
    rowsToRender: number;
};

const getComponentByItem = (
    item: Item,
    isFetched: boolean,
    tracking: PulseTracking,
) => {
    if (itemIsAdvertising(item)) {
        return (
            <AdvertisingAd
                key={item.itemId}
                item={item}
                tracking={tracking}
                inscreenTrackingIsEnabled={isFetched}
            />
        );
    }
    return (
        <Ad
            key={item.itemId}
            item={item}
            tracking={tracking}
            inscreenTrackingIsEnabled={isFetched}
        />
    );
};

export default function Ads(props: AdsProps) {
    const { position, recommender, adId, numColumns, rowsToRender } = props;

    const { urls, brand, overrideTitle } = useAppConfig();
    const { apiUrl } = urls;

    const [renderAllRows, setRenderAllRows] = useState(
        rowsToRender === 0 || !rowsToRender,
    );

    const nodeRef = useRef<HTMLDivElement>(null);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null);

    const { recommendationData: initialData, isFetched: initialIsFetched } =
        usePrefetchRecommendationDataQuery();

    const fetchMoreUrlsPaged = renderAllRows
        ? initialData?.fetchMore
        : (initialData?.fetchMore || []).slice(0, rowsToRender);

    const { currentFetchMoreData, isFetched, hasMoreFetchData } =
        useInfiniteFetchMoreData({
            apiUrl,
            ref: nodeRef,
            recommender,
            position,
            fetchMoreUrls: fetchMoreUrlsPaged,
        });

    const tracking = useMemo(
        () =>
            new PulseTracking({
                position,
                trackingContext: initialData?.['tracking-context'] ?? {},
            }),
        [position, initialData],
    );

    const elements = useMemo(
        () =>
            (initialData?.items ?? []).map((item) =>
                getComponentByItem(item, initialIsFetched, tracking),
            ),
        [initialData, initialIsFetched, tracking],
    );

    const fetchMoreElements = useMemo(
        () =>
            currentFetchMoreData?.pages?.reduce(
                (acc, page: RecommendationData) => {
                    const items = page?.items;
                    if (items) {
                        acc.push(
                            ...items.map((item) =>
                                getComponentByItem(item, isFetched, tracking),
                            ),
                        );
                    }
                    return acc;
                },
                [] as JSX.Element[],
            ),
        [currentFetchMoreData, isFetched, tracking],
    );

    const allElements = useMemo(
        () => [...elements, ...fetchMoreElements],
        [elements, fetchMoreElements],
    );

    const isFrontpage = position?.includes('front');
    const isPersonal = initialData?.isPersonal;

    //show Oikotie only on tori
    let adItems = allElements;
    if (brand.toUpperCase() === 'TORI' && isFrontpage) {
        adItems = insertOikotieAd(allElements, iframeRef);
    }

    const adsTitle = useMemo(() => {
        if (overrideTitle) {
            return overrideTitle;
        }
        return initialIsFetched
            ? getTitle(adId, isPersonal, elements?.length)
            : '';
    }, [initialIsFetched, adId, isPersonal, elements?.length, overrideTitle]);

    const { bottomRef, isBottomReached } = useBottomReached({
        onBottomReachedFn: () => {
            if (!hasMoreFetchData) {
                fetch(`${apiUrl}/tracking`);
            }
        },
    });

    return (
        <div>
            <div
                className="grid f-grid grid-flow-row-dense my-16"
                style={{ alignItems: 'center', display: 'flex' }}
            >
                <h1 className="text-ml">{adsTitle}</h1>
                {showHistoryLink(
                    adItems?.length,
                    brand,
                    isFrontpage,
                    isPersonal,
                ) && (
                    <a
                        href="/anbefalinger/historikk"
                        className="link text-14 px-8"
                    >
                        Hvorfor anbefaler vi disse annonsene?
                    </a>
                )}
            </div>
            <div
                className={`grid f-grid grid-flow-row-dense grid-cols-2 ${
                    numColumns === 5 ? 'lg:grid-cols-5' : 'lg:grid-cols-3'
                } gap-10`}
            >
                {adItems}
                <div ref={nodeRef} />
                <div ref={bottomRef} style={{ height: '1px' }}></div>
            </div>
            <div className="text-center my-16">
                {showLoadMoreButton(
                    renderAllRows,
                    isBottomReached,
                    hasMoreFetchData,
                ) && (
                    <Button
                        primary
                        className="leading-m"
                        onClick={() => {
                            setRenderAllRows(true);
                        }}
                        ref={loadMoreButtonRef}
                    >
                        {'Se flere anbefalinger'}
                    </Button>
                )}
            </div>
        </div>
    );
}
