import React, { useRef, useState } from 'react';
import { Item } from '../data/recommendationDataTypes';
import createHeartComponent from '@finn-no/favorite-heart-component';
import PulseTracking from '../tracking/pulseTracking';
import { useTrackInscreen } from '../tracking/trackingHooks';
import { getAdProperties } from './adHelpers';
import { useAppConfig } from '../utils/AppConfig';
import ImageWrapper from './wrappers/ImageWrapper';
import LabelsWrapper from './wrappers/LabelsWrapper';

type AdProps = {
    item: Item;
    tracking: PulseTracking;
    inscreenTrackingIsEnabled: boolean;
};

const Ad: React.FC<AdProps> = (props) => {
    const { item, tracking, inscreenTrackingIsEnabled } = props;
    const { urls, loginId, locale } = useAppConfig();
    const { cdnBaseUrl, favoriteBaseUrl } = urls;
    const domRef = useRef<HTMLDivElement>(null);

    const wcRef =
        typeof document !== 'undefined'
            ? document
                  .querySelector('recommendation-podlet-isolated')
                  ?.shadowRoot.getElementById('recommendation-podlet')
            : null;

    const Heart = createHeartComponent({
        baseUri: favoriteBaseUrl,
        userId: loginId,
        renderServerSide: true,
        modalAttach: wcRef ? wcRef : null,
    });

    const handleAdClick = (event) => {
        event.preventDefault();
        tracking.trackClickWithPulse(item.tracking?.pulse);
        setTimeout(() => {
            window.location.href = `/${item.itemId}`;
        }, 200);
    };

    const [hasBeenTracked, setHasBeenTracked] = useState(false);

    useTrackInscreen({
        tracking,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
        enabled: inscreenTrackingIsEnabled,
        hasBeenTracked,
        setHasBeenTracked,
    });

    const {
        itemIdNumber,
        shippingLabel,
        p4pLabel,
        easyapplyLabel,
        isCmsArticle,
    } = getAdProperties(item);

    return (
        <article
            className="relative isolate recommendation-ad card card--cardShadow s-bg"
            ref={domRef}
        >
            {item?.image?.url && (
                <ImageWrapper item={item} cdnBaseUrl={cdnBaseUrl} />
            )}

            <div className="text-s s-text-subtle mx-16 mt-8">
                <span className="nonEmpty">{item.location?.combined}</span>
            </div>

            <div className="mx-16 mt-6 mb-14">
                <h2 className="t4 mb-0 break-words" id={item.itemId}>
                    <a
                        className="recommendation-ad-link s-text! hover:no-underline line-clamp"
                        href={`/${item.itemId}`}
                        id={item.itemId}
                        onClick={handleAdClick}
                    >
                        <span
                            className="absolute inset-0"
                            aria-hidden="true"
                        ></span>
                        {item.heading}
                    </a>
                </h2>

                {/* company and custom labels */}
                <div className="text-s s-text-subtle mt-8">
                    {item.content?.company_name && (
                        <div>{item.content?.company_name}</div>
                    )}
                    {item.customLabel && <div>{item.customLabel}</div>}
                </div>
            </div>

            {item?.type?.toUpperCase() !== 'CMS' && (
                <div className="absolute top-16 right-16">
                    <Heart
                        variant="heart-on-image"
                        itemType="Ad"
                        locale={locale as 'en' | 'nb' | 'fi'}
                        itemId={itemIdNumber}
                        isMobile={false}
                        renderNumFavs={false}
                    />
                </div>
            )}

            <LabelsWrapper
                shippingLabel={shippingLabel}
                easyapplyLabel={easyapplyLabel}
                p4pLabel={p4pLabel}
                isCmsArticle={isCmsArticle}
            />

            <div className="absolute inset-0 z-10 pointer-events-none outline-none rounded-8 border-2 border-transparent transition-all recommendation-ad-outline"></div>
        </article>
    );
};

export default Ad;
